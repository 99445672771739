<template>
  <div>
    <portal to="page-top-title">Users</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'users-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/users" :url-query="{ with: ['company:id,name', 'roles:name'] }" data-prop="users" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Company</th>
          <th>Name</th>
          <th>Email</th>
          <th>Roles</th>
          <th class="text-center">Active</th>
          <th class="text-center">2FA</th>
          <th class="text-right">Dates</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="user in users" :key="`users-tr-${user.id}`" :value="user">
          <td>{{ user.id }}</td>
          <td>
            <router-link :to="{ name: 'companies-single', params: { id: user.company.id } }">{{ user.company.name }}</router-link>
          </td>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>
            <template v-for="(role, r) in user.roles">
              <span :key="`user-${user.id}-role-${r}-comma`" v-if="r !== 0">, </span>
              <code :key="`user-${user.id}-role-${r}`">{{ $helpers.Str.titleCase(role.name) }}</code>
            </template>
          </td>
          <td class="text-center">
            <badge-active :value="user.active"/>
          </td>
          <td class="text-center">
            <badge-active :value="user.tfa_enabled"/>
          </td>
          <td class="text-right">
            <div class="td-rows columns divider">
              <div class="td-row">
                <span class="left">Created At:</span>
                <span class="right">{{ user.created_at }}</span>
              </div>
              <div class="td-row">
                <span class="left">Updated At:</span>
                <span class="right">
                  <nullable :value="user.updated_at"/>
                </span>
              </div>
              <div class="td-row">
                <span class="left">Pswd Updated At:</span>
                <span class="right">
                  <nullable :value="user.pswd_updated_at"/>
                </span>
              </div>
            </div>
          </td>
          <td class="text-right">
            <template v-if="user.metadata.editable.this">
              <btn :route="{ name: 'users-edit', params: { id: user.id } }" icon="fa-edit">Edit</btn>
            </template>
            <btn :route="{ name: 'users-single', params: { id: user.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Btn from '@/views/components/simple/Btn'
import Nullable from '@/views/components/simple/Nullable'

export default {
  name: 'UsersList',
  metaInfo: {
    title: 'Users'
  },
  components: {
    BoxTable,
    BadgeActive,
    Btn,
    Nullable
  },
  data () {
    return {
      users: []
    }
  }
}
</script>
